import { includes } from 'lodash';
import {
  CASHOUT_TRANSACTION_STATUS,
  TRANSACTION_TYPE,
} from '../constants/constant-list';
import {
  ReceiverInformation,
  SenderInformation,
} from '../interfaces/RemiitancePlatforms';

// transaction_type
export const IS_TRANSFER = (transact_type: string) =>
  transact_type === TRANSACTION_TYPE.TRANSFER ||
  transact_type === TRANSACTION_TYPE.TRANSFER2;

export const IS_TRANSACTION_CASH_IN = (transact_type: string) =>
  transact_type === TRANSACTION_TYPE.CASH_IN ||
  transact_type === TRANSACTION_TYPE.CASH_IN_OLD;

export const IS_TRANSACTION_CASH_OUT = (transact_type: string) =>
  transact_type === TRANSACTION_TYPE.CASH_OUT ||
  transact_type === TRANSACTION_TYPE.CASH_OUT2;

export const IS_TRANSACTION_PAYMENT = (transact_type: string) =>
  transact_type === TRANSACTION_TYPE.PAYMENT;

export const IS_TRANSACTION_REMITTANCE = (transact_type: string) =>
  transact_type === TRANSACTION_TYPE.REMITTANCE ||
  transact_type === TRANSACTION_TYPE.RECEIVED;

// transaction_object_type
export const IS_TRANSACTION_TRANSFER = (transact_obj_type: string) =>
  transact_obj_type === TRANSACTION_TYPE.PERSON_TO_PERSON ||
  transact_obj_type === TRANSACTION_TYPE.BUSINESS_TO_PERSON ||
  transact_obj_type === TRANSACTION_TYPE.PERSON_TO_BUSINESS ||
  transact_obj_type === TRANSACTION_TYPE.CASH_IN ||
  transact_obj_type === TRANSACTION_TYPE.CASH_IN2 ||
  transact_obj_type === TRANSACTION_TYPE.CASH_IN_OLD ||
  transact_obj_type === TRANSACTION_TYPE.DONATION ||
  transact_obj_type === TRANSACTION_TYPE.TOP_UP2 ||
  transact_obj_type === TRANSACTION_TYPE.TOP_UP;

export const IS_TRANSACTION_TOP_UP = (transact_obj_type: string) =>
  transact_obj_type === TRANSACTION_TYPE.TOP_UP2 ||
  transact_obj_type === TRANSACTION_TYPE.TOP_UP ||
  transact_obj_type === TRANSACTION_TYPE.CASH_IN_OLD ||
  transact_obj_type === TRANSACTION_TYPE.CASH_IN2;

export const IS_TRANSACTION_DONATION = (transact_type: string) =>
  transact_type === TRANSACTION_TYPE.DONATION;

export const IS_TRANSACTION_P2P = (transact_obj_type: string) =>
  transact_obj_type === TRANSACTION_TYPE.PERSON_TO_PERSON;

export const IS_TRANSACTION_B2P = (transact_obj_type: string) =>
  transact_obj_type === TRANSACTION_TYPE.BUSINESS_TO_PERSON; //newly added

export const IS_TRANSACTION_P2B = (transact_obj_type: string) =>
  transact_obj_type === TRANSACTION_TYPE.PERSON_TO_BUSINESS; //newly added

export const IS_RIA_REMITTANCE = (remittance_platform: string) =>
  includes(remittance_platform, 'Ria');

export const IS_TRANSACTION_STATUS_ACCEPTED = (status: string) =>
  status === CASHOUT_TRANSACTION_STATUS.ACCEPTED ||
  status === CASHOUT_TRANSACTION_STATUS.CASHING_OUT ||
  status === CASHOUT_TRANSACTION_STATUS.CASHED_OUT;

export const IS_TRANSACTION_STATUS_PENDING = (status: string) =>
  status === CASHOUT_TRANSACTION_STATUS.PENDING;

export const IS_TRANSACTION_STATUS_CREDITED = (status: string) =>
  status === CASHOUT_TRANSACTION_STATUS.CREDITED;

export const IS_TRANSACTION_STATUS_RECEIVED_CREATED = (status: string) =>
  status === CASHOUT_TRANSACTION_STATUS.RECEIVED ||
  status === CASHOUT_TRANSACTION_STATUS.CREATED;

export const IS_TRANSACTION_STATUS_RETURNED_EXPIRED = (status: string) =>
  status === CASHOUT_TRANSACTION_STATUS.EXPIRED ||
  status === CASHOUT_TRANSACTION_STATUS.RETURNED;

export const IS_TRANSACTION_STATUS_REJECTED_CANCELLED = (status: string) =>
  status === CASHOUT_TRANSACTION_STATUS.REJECTED ||
  status === CASHOUT_TRANSACTION_STATUS.CANCELLED;

export const IS_TRANSACTION_STATUS_RETURNED_REJECTED = (status: string) =>
  status === CASHOUT_TRANSACTION_STATUS.REJECTED ||
  status === CASHOUT_TRANSACTION_STATUS.RETURNED;

export const IS_TRANSACTION_CANCELLABLE = (
  transact_type: string,
  status: string
) =>
  status !== CASHOUT_TRANSACTION_STATUS.EXPIRED &&
  status !== CASHOUT_TRANSACTION_STATUS.FAILED &&
  status !== CASHOUT_TRANSACTION_STATUS.COMPLETED &&
  status !== CASHOUT_TRANSACTION_STATUS.CANCELLED &&
  status !== CASHOUT_TRANSACTION_STATUS.CASHED_OUT &&
  status !== CASHOUT_TRANSACTION_STATUS.RETURNED &&
  status === CASHOUT_TRANSACTION_STATUS.PENDING &&
  IS_TRANSACTION_CASH_OUT(transact_type);

const IS_P2P_RECEIVER = (user_id: number, receiver: ReceiverInformation) => {
  return receiver?.app_user_id === user_id;
};

const IS_P2P_SENDER = (user_id: number, sender: SenderInformation) => {
  return sender?.app_user_id === user_id;
};
