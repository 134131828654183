<mat-dialog-content>
  <div class="card-heading">Transaction Details</div>
  <div class="d-flex">
    <div class="col-md-7 p-0" *ngIf="details.type === 'PAYMENT'">
      <div class="detail-height">
        <div class="label-name text-style mb-1">Merchant Name</div>
        <div class="label-value text-style">
          {{ details?.transaction_object?.merchant_name ?? "-" }}
        </div>
      </div>
      <div class="detail-height">
        <div class="label-name text-style mb-1">Amount</div>
        <div class="label-value text-style">
          {{ details?.transaction_object?.amount ?? "-" }}
        </div>
      </div>
      <div class="detail-height">
        <div class="label-name text-style mb-1">Payment On</div>
        <div class="label-value text-style">
          {{
            details?.transaction_object?.datetime_updated | date : "shortTime"
          }}
          -
          {{
            details?.transaction_object?.datetime_updated
              | date : " EEE, MMM d, y"
          }}
        </div>
      </div>
      <div class="detail-height">
        <div class="label-name text-style mb-1">Referrence Number</div>
        <div class="label-value text-style">
          {{ details?.transaction_object?.partner_reference ?? "-" }}
        </div>
      </div>
    </div>

    <div class="col-md-7 p-0" *ngIf="details.type !== 'PAYMENT'">
      <div class="detail-height">
        <div class="label-name text-style mb-1">{{ labelName }}</div>
        <div
          [ngClass]="
            profileID !== 0
              ? 'label-value text-style link-bold-underline'
              : 'label-value text-style unregistered'
          "
          (click)="onViewProfile(profileID)"
        >
          {{
            renderTransactionName(
              details.type,
              details.transaction_type,
              details.sender,
              details.receiver,
              details.transaction_object
            )
          }}
        </div>
      </div>
      <div class="detail-height" *ngIf="details.type === 'REMITTANCE'">
        <div class="label-name text-style mb-1">
          Receiver name entered by the sender
        </div>
        <ng-container>
          <div class="label-value text-style">
            {{ details?.transaction_object?.receiver_name ?? "-" }}
          </div>
        </ng-container>
      </div>
      <div class="detail-height">
        <div class="label-name text-style mb-1">Remittance Platform</div>
        <div class="label-value text-style">
          {{
            renderRemittancePlatform(
              details.type,
              details.transaction_object
            ) ?? "-"
          }}
        </div>
      </div>
      <div class="detail-height">
        <div class="label-name text-style mb-1">Sender relation</div>
        <div class="label-value text-style">
          {{
            renderRelationship(details.type, details.transaction_object) ?? "-"
          }}
        </div>
      </div>
      <div class="detail-height">
        <div class="label-name text-style mb-1">Cash out partner</div>
        <div class="label-value text-style">
          {{ details?.transaction_object?.cash_out_partner_name ?? "-" }}
        </div>
      </div>
      <div class="detail-height" *ngIf="details.type === 'CASH_OUT'">
        <div class="label-name text-style mb-1">Cash out fee</div>
        <div class="label-value text-style">
          {{
            this.details?.transaction_object?.extra_data?.amounts?.fees
              ?.value ?? "-"
          }}
          {{
            this.details?.transaction_object?.extra_data?.amounts?.fees
              ?.currency ?? ""
          }}
        </div>
      </div>
    </div>

    <div class="col-md-5 p-0" *ngIf="details.type !== 'PAYMENT'">
      <div class="detail-height">
        <div class="label-name text-style mb-1">
          {{ renderLabelDate(details.type, details.transaction_object) }}
        </div>
        <div class="label-value text-style">
          {{
            details?.transaction_object?.datetime_created | date : "shortTime"
          }}
          -
          {{
            details?.transaction_object?.datetime_created
              | date : " EEE, MMM d, y"
          }}
        </div>
      </div>
      <div class="detail-height">
        <div class="label-name text-style mb-1">
          {{ locationLabel }} Location
        </div>
        <div class="label-value text-style">
          {{ renderSenderCountry(details.type) ?? "-" }}
        </div>
      </div>
      <div class="detail-height">
        <div class="label-name text-style mb-1">Transfer reason</div>
        <div class="label-value text-style">
          {{ renderTransferReason(details.type) ?? "-" }}
        </div>
      </div>
      <div class="detail-height">
        <div class="label-name text-style mb-1">Transaction ID</div>
        <div class="label-value text-style">
          {{ details?.transaction_object?.partner_reference ?? "-" }}
        </div>
      </div>
      <div class="detail-height">
        <div class="label-name text-style mb-1">Referrence Number</div>
        <div class="label-value text-style" *ngIf="details.type !== 'TRANSFER'">
          {{ details?.transaction_object?.purpl_reference ?? "-" }}
        </div>
      </div>
      <div class="detail-height">
        <div class="label-name text-style mb-1">Amount</div>
        <div class="label-value text-style">
          {{ details?.amount ?? "-" | number }}
        </div>
      </div>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions class="actions-btn">
  <a
    class="button button-text suspension-btn"
    *ngIf="
      details?.status === 'Received' &&
      details?.status === 'Cashing Out' &&
      checkAccessModule(
        FEATURES.REMITTANCE_PLATFORMS_LISTING.name,
        PERMISSION.DELETE
      ) &&
      checkAccessModule(
        FEATURES.REMITTANCE_PLATFORMS_ADD.name,
        PERMISSION.DELETE
      )
    "
  >
    Suspend transaction
  </a>
  <a class="button button-text close" (click)="close()"> Close</a>
</mat-dialog-actions>
