import { Component, Inject, Injector, OnInit } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { isEmpty, toUpper } from 'lodash';
import { SuspendTransactionPromptConfirmationComponent } from 'src/app/pages/suspensions/components/suspend-transaction-prompt-confirmation/suspend-transaction-prompt-confirmation.component';
import { ListingBaseComponent } from 'src/app/shared/components/base/listing-base.component';
import { CACHE_URL_KEY } from 'src/app/shared/constants/constant-list';
import {
  ReceiverInformation,
  SenderInformation,
  UserTransactionsObject,
} from 'src/app/shared/interfaces/RemiitancePlatforms';
import { LocalStorageService } from 'src/app/shared/services';
import {
  IS_TRANSACTION_B2P,
  IS_TRANSACTION_CASH_IN,
  IS_TRANSACTION_CASH_OUT,
  IS_TRANSACTION_DONATION,
  IS_TRANSACTION_P2B,
  IS_TRANSACTION_P2P,
  IS_TRANSACTION_PAYMENT,
  IS_TRANSACTION_REMITTANCE,
  IS_TRANSACTION_TOP_UP,
  IS_TRANSFER,
} from 'src/app/shared/utils/transaction_utils';

@Component({
  selector: 'app-user-profile-transaction-details',
  templateUrl: './user-profile-transaction-details.component.html',
  styleUrls: ['./user-profile-transaction-details.component.scss'],
})
export class UserProfileTransactionDetailsComponent
  extends ListingBaseComponent
  implements OnInit
{
  details: any;
  locationLabel?: string;
  cashOutLocation?: string;
  senderCountry?: string;
  transferReason?: string;
  recepient?: string;

  transactionName?: string;
  labelName?: string;
  remittancePlatform?: string;
  relationship?: string;
  labelDate?: string;
  userID?: number;
  profileID?: number;

  constructor(
    injector: Injector,
    private localService: LocalStorageService,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      details: any;
      userID: number;
    },
    public dialogRef: MatDialogRef<UserProfileTransactionDetailsComponent>
  ) {
    super(injector);

    this.details = data.details;
    this.userID = data.userID;
  }

  ngOnInit(): void {
    this.locationLabel =
      this.details.type === 'CASH_OUT' ? 'Cash out' : 'Sender';
  }

  onSuspendTransaction(transaction: any, isSuspend: boolean) {
    this.dialog.open(SuspendTransactionPromptConfirmationComponent, {
      data: {
        isSuspend: !isSuspend,
        transaction: transaction,
        suspend: 'transaction',
      },
      width: '500px',
    });
  }

  renderTransactionName(
    type: string,
    transaction_type: string,
    sender: SenderInformation,
    receiver: ReceiverInformation,
    transaction_object: UserTransactionsObject
  ) {
    const TRANSACTION_TYPE = toUpper(transaction_type);
    const TYPE_ = toUpper(type);

    if (IS_TRANSFER(TYPE_)) {
      if (IS_TRANSACTION_P2P(TRANSACTION_TYPE)) {
        if (this.userID === sender?.app_user_id) {
          this.transactionName = !isEmpty(receiver?.app_user)
            ? receiver?.app_user?.first_name_en +
              ' ' +
              receiver?.app_user?.last_name_en
            : receiver?.first_name + ' ' + receiver?.last_name;
          this.labelName = 'Sent to';
          this.profileID = receiver?.app_user_id;
        } else if (this.userID === receiver?.app_user_id) {
          if (!isEmpty(sender?.app_user)) {
            this.transactionName =
              sender?.app_user?.first_name_en +
              ' ' +
              sender?.app_user?.last_name_en;
          } else {
            this.transactionName = sender?.name
              ? sender?.name
              : sender.first_name + ' ' + sender.last_name;
          }
          this.labelName = 'Received from';
          this.profileID = sender?.app_user_id;
        }
      } else if (
        IS_TRANSACTION_B2P(TRANSACTION_TYPE) ||
        IS_TRANSACTION_P2B(TRANSACTION_TYPE) ||
        IS_TRANSACTION_TOP_UP(TRANSACTION_TYPE)
      ) {
        this.transactionName = sender?.name;
        this.labelName = 'Received from';
        this.profileID = sender?.app_user === null ? 0 : sender?.app_user_id;
      } else if (IS_TRANSACTION_DONATION(TRANSACTION_TYPE)) {
        this.transactionName = receiver?.first_name + ' ' + receiver?.last_name;
        this.labelName = 'Received from';
        this.profileID = receiver?.app_user_id;
      }
    } else if (IS_TRANSACTION_CASH_OUT(TYPE_)) {
      this.transactionName = transaction_object?.final_cash_out_branch_name;
      this.labelName = 'Received from';
      this.profileID = sender?.app_user_id;
    } else if (IS_TRANSACTION_REMITTANCE(TYPE_)) {
      this.transactionName = transaction_object?.sender_name;
      this.labelName = 'Received from';
      this.profileID = 0;
    } else if (IS_TRANSACTION_CASH_IN(TYPE_)) {
      this.transactionName = transaction_object?.branch?.name;
      this.labelName = 'Sent to';
      this.profileID = 0;
    } else if (IS_TRANSACTION_PAYMENT(TYPE_)) {
      this.transactionName = transaction_object?.merchant_name;
      this.labelName = 'Received from';
      this.profileID = sender?.app_user_id;
    }

    return this.transactionName;
  }

  onViewProfile(event: any): void {
    if (event) {
      this.localService.setDataInLocalStorage({
        key: CACHE_URL_KEY,
        value: this.params.toString(),
      });
      const url = this.router.serializeUrl(
        this.router.createUrlTree([`/users/app-users/${event}`])
      );
      window.open(url, '_blank');
    }
  }

  renderRemittancePlatform(
    type: string,
    transaction_object: UserTransactionsObject
  ) {
    const TYPE_ = toUpper(type);

    if (IS_TRANSFER(TYPE_)) {
      // this.remittancePlatform = transaction_object?.remittance?.platform_name;
      this.remittancePlatform = '';
    } else {
      this.remittancePlatform = transaction_object?.remittance_platform_name;
    }
    return this.remittancePlatform;
  }

  renderRelationship(type: string, transaction_object: UserTransactionsObject) {
    const TYPE_ = toUpper(type);

    this.relationship = IS_TRANSFER(TYPE_)
      ? transaction_object?.sender_receiver_relationship
      : transaction_object?.sender_relationship_to_receiver;
    return this.relationship;
  }

  renderLabelDate(type: string, transaction_object: UserTransactionsObject) {
    const TYPE_ = toUpper(type);
    this.labelDate = IS_TRANSACTION_CASH_OUT(TYPE_)
      ? 'Cashed out On'
      : ' Received On';

    return this.labelDate;
  }

  renderSenderCountry(type: string) {
    const TYPE_ = toUpper(type);
    if (IS_TRANSACTION_CASH_OUT(TYPE_) || IS_TRANSFER(TYPE_)) {
      // this.senderCountry = this.details?.transaction_object?.sender?.country;
      this.senderCountry =
        this.details?.transaction_object?.final_cash_out_branch_name;
    } else {
      this.senderCountry = this.details?.transaction_object?.sender_country;
    }

    return this.senderCountry;
  }

  renderTransferReason(type: string) {
    const TYPE_ = toUpper(type);

    this.transferReason = IS_TRANSFER(TYPE_)
      ? this.details?.transaction_object?.purpose
      : this.details?.transaction_object?.transfer_purpose;

    return this.transferReason;
  }

  close() {
    this.dialogRef.close();
  }
}

// onViewSenderProfile(event: any): void {
//   if (event?.transaction_object?.sender?.app_user_id) {
//     this.localService.setDataInLocalStorage({
//       key: CACHE_URL_KEY,
//       value: this.params.toString(),
//     });
//     const url = this.router.serializeUrl(
//       this.router.createUrlTree([
//         `/users/app-users/${event?.transaction_object?.sender?.app_user_id}`,
//       ])
//     );
//     window.open(url, '_blank');
//   }
// }
